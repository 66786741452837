import React from "react"
import { motion } from "framer-motion"
import ScaleUp from "../utility/scaleUpAnimation"
import SlideInRight from "../utility/slideInRightAnimation"

const PrimaryQuote = props => (
  <div className="section is-medium">
    <div className="container is-narrow">
      <div className="columns is-vcentered">
        <div className="column is-4">
          <div className="content">
            <ScaleUp>
              <div className="content primaryblockquote">
                <h3 className="is-white" style={{ lineHeight: "1.5" }}>
                  {props.quoteTitle}
                </h3>
                {props.quoteDescription}
              </div>
            </ScaleUp>
          </div>
        </div>
        <div className="column is-7">
          <SlideInRight>
            <div
              className="content"
              style={{ border: "0px solid #35b775", padding: "30px 30px" }}
            >
              <h2 style={{ lineHeight: "1.5" }}>{props.quoteHighlight}</h2>
            </div>
          </SlideInRight>
        </div>
      </div>
    </div>
  </div>
)

export default PrimaryQuote
