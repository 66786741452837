import React from "react"
import Highlight from "./highlight"
import SlideInTop from "../utility/slideInTopAnimation"
import SlideInBottom from "../utility/slideInBottomAnimation"

const HighlightsSeries = props => (
  <div className="section">
    <div className="container">
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column is-8">
          <div className="content is-large has-text-centered">
            <SlideInBottom><h2 style={{ paddingBottom: "50px", }}>{props.mainTitle}</h2></SlideInBottom>
          </div>
          <div className="content">
            <SlideInTop><h3 style={{ paddingBottom: "50px", lineHeight: "1.5"}}>
              {props.title}
            </h3></SlideInTop>
            <Highlight
              main={props.mainOne}
              description={props.descriptionOne}
            />
            {props.mainTwo && (
            <Highlight
              main={props.mainTwo}
              description={props.descriptionTwo}
            />
            )}
            {props.mainThree && (
            <Highlight
              main={props.mainThree}
              description={props.descriptionThree}
            />
            )}
            {props.mainFour && (
              <Highlight
                main={props.mainFour}
                description={props.descriptionFour}
              />
            )}
            {props.mainFive && (
              <Highlight
                main={props.mainFive}
                description={props.descriptionFive}
              />
            )}
            {props.mainSix && (
              <Highlight
                main={props.mainSix}
                description={props.descriptionSix}
              />
            )}
            {props.mainSeven && (
              <Highlight
                main={props.mainSeven}
                description={props.descriptionSeven}
              />
            )}
            {props.mainEight && (
              <Highlight
                main={props.mainEight}
                description={props.descriptionEight}
              />
            )}
            {props.mainNine && (
              <Highlight
                main={props.mainNine}
                description={props.descriptionNine}
              />
            )}
            {props.mainTen && (
              <Highlight
                main={props.mainTen}
                description={props.descriptionTen}
              />
            )}
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  </div>
)

export default HighlightsSeries
