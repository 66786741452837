import React from 'react'
import Img from 'gatsby-image'

const Card = props => (
  <div className="card is-shady">
    <div className="columns is-vcentered">
      {props.fixedImage && (
        <div className="column is-2 has-text-centered is-bottom-marginless">
          <Img
            css={props.imageCss}
            style={props.imageStyle}
            fixed={props.fixedImage}
            alt={props.alt}
          />
        </div>
      )}
      <div className="column is-10">
        <div className="card-content">
          <div className="media">
            <div className="media-content has-text-centered-mobile">
              <p className="title is-4 is-primary is-bottom-marginless">
                {props.cardTitle}
              </p>
              <p classname="is-subtitle">{props.cardSubtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Card
