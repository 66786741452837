import React from 'react'
import SlideInLeft from '../utility/slideInLeftAnimation'
import SlideInBottom from '../utility/slideInBottomAnimation'
import Img from 'gatsby-image'

const Section1 = props => (
  <div className={`section is-medium ${props.isGradient} is-relative`}>
    {props.fluid && (
      <Img
        fluid={props.fluid}
        style={{
          position: 'absolute',
          overflow: 'hidden',
        }}
        imgStyle={{
          width: '100%',
          height: '100%',
          opacity: '0.05',
        }}
        className="image-wrapper-height-auto"
        alt={props.alt}
      />
    )}
    <div className="container is-narrow">
      <div className="columns is-vcentered is-multiline">
        <div className="column is-2"></div>
        <div className="column is-5">
          <SlideInLeft>
            <div className="content is-medium">
              <h2 className={`title is-1 ${props.titleColor}`}>
                {props.section1Title}
              </h2>
            </div>
          </SlideInLeft>
        </div>
        <div className="column is-5"></div>

        <div className="column is-2"></div>
        <div className="column is-3 has-text-centered"></div>
        <div class="column is-5">
          <SlideInBottom>
            <div className="content has-text-justified">
              <p>{props.section1Text}</p>
            </div>
          </SlideInBottom>
        </div>
      </div>
    </div>
  </div>
)

export default Section1
