import React from "react"
import { motion } from "framer-motion"
import ScaleUp from "../utility/scaleUpAnimation"
import SlideInLeft from "../utility/slideInLeftAnimation"

const Highlight = props => (
  <div className="columns is-vcentered is-centered">
    <div className="column is-6">
      <ScaleUp>
        <div className="content primaryblockquote">
          <h4 className="is-white" style={{ marginBottom: "0rem", lineHeight: "1.5"}}>
            {props.main}
          </h4>
        </div>
      </ScaleUp>
    </div>
    <SlideInLeft>
      {props.description && (
        <div
          className="column"
          style={{
            border: "2px solid #35b775",
            padding: "30px",
            marginBottom: "10px",
          }}
        >
          <p>{props.description}</p>
        </div>
      )}
    </SlideInLeft>
  </div>
)

export default Highlight
