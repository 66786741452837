import React from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const ScaleUpInfinity = ({ children }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })

  return (
    <motion.div
      animate={
        inView
          ? {
              scale: [0, 1.2, 1, 0],
            }
          : {
              scale: 0,
            }
      }
      transition={{ duration: 2, loop: Infinity }}
      ref={ref}
    >
      {children}
    </motion.div>
  )
}

ScaleUpInfinity.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScaleUpInfinity
