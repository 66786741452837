import React from "react"
import SlideInTop from "../utility/slideInTopAnimation"

const Paragraph = props => (
  <>
    <div className="content" style={{ padding: "2rem 0rem" }}>
      <SlideInTop>
        <h3 style={{ lineHeight: "1.5" }}>{props.title}</h3>
      </SlideInTop>
      <SlideInTop>
        <p className={`${props.textWithBackground}`}>{props.text}</p>
      </SlideInTop>
    </div>
  </>
)

export default Paragraph
