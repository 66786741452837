import React from "react"
import Img from "gatsby-image"
import Paragraph from "./paragraph"
import SlideInTop from "../utility/slideInTopAnimation"

const TextImageRight = props => (
  <div className="section">
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column">
          <Paragraph title={props.titleOne} text={props.textOne} textWithBackground={props.textWithBackground}/>
          <Paragraph title={props.titleTwo} text={props.textTwo} textWithBackground={props.textWithBackground}/>
          {props.titleThree && (
            <Paragraph title={props.titleThree} text={props.textThree}  textWithBackground={props.textWithBackground}/>
          )}
          {props.titleFour && (
            <Paragraph title={props.titleFour} text={props.textFour} textWithBackground={props.textWithBackground}/>
          )}
        </div>
        <div className="column is-1"></div>
        {props.fixed && (
          <div className="column">
            <SlideInTop>
              <Img fixed={props.fixed} style={props.style} alt={props.alt} />
            </SlideInTop>
          </div>
        )}
        {props.animatedIllustration && (
          <div className="column">
            <SlideInTop>{props.animatedIllustration}</SlideInTop>
          </div>
        )}
      </div>
    </div>
  </div>
)

export default TextImageRight
