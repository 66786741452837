import React from "react"
import Card from "../common/card"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import SlideInTop from "../utility/slideInTopAnimation"
import ScaleUpInfinity from "../utility/scaleUpInfinityAnimation"
import { useInView } from "react-intersection-observer"

const Section2 = props => {
  const [ref, inView] = useInView({ threshold: 0.5 })
  return (
    <div className="section is-gradient-bottom-right is-relative">
      {props.fluid && (
        <Img
          fluid={props.fluid}
          style={{
            position: "absolute",
            overflow: "hidden",
          }}
          imgStyle={{
            width: "100%",
            height: "100%",
            opacity: "0.1",
          }}
          className="image-wrapper-height-auto"
          alt={props.alt}
        />
      )}
      <div className="container is-narrow">
        <div className="hero">
          <div className="hero-body">
            <div className="columns is-multiline is-vcentered">
              <div className="column is-2"></div>
              <div className="column is-6">
                <SlideInTop>
                  <div className="content is-medium">
                    <h2 className="title is-1 is-white">
                      {props.section2Title}
                    </h2>
                  </div>
                </SlideInTop>
              </div>
              <div className="column is-4"></div>
              <div className="column is-2"></div>
              <div className="column is-2">
                <ScaleUpInfinity>
                  {props.fixed && <Img fixed={props.fixed} alt={props.alt} />}
                </ScaleUpInfinity>
              </div>
              <div className="column is-7">
                <div className="columns is-multiline">
                  <div className="column is-6">
                    <motion.div
                      animate={
                        inView
                          ? {
                              scale: [0, 1.2, 1],
                              opacity: [0, 1],
                            }
                          : {
                              scale: 0,
                              opacity: [0, 0],
                            }
                      }
                      ref={ref}
                      transition={{
                        duration: 1.5,
                        delay: 0.5,
                      }}
                    >
                      <Card
                        cardTitle={props.card1Title}
                        imageCss={props.card1Imagecss}
                        imageStyle={props.card1Imagestyle}
                        fixedImage={props.card1Fixedimage}
                        alt={props.alt}
                      />
                    </motion.div>
                  </div>
                  <div className="column is-6">
                    <motion.div
                      animate={
                        inView
                          ? {
                              scale: [0, 1.2, 1],
                              opacity: [0, 1],
                            }
                          : {
                              scale: 0,
                              opacity: [0, 0],
                            }
                      }
                      ref={ref}
                      transition={{
                        duration: 1.5,
                        delay: 0.7,
                      }}
                    >
                      <Card
                        cardTitle={props.card2Title}
                        imageCss={props.card2Imagecss}
                        imageStyle={props.card2Imagestyle}
                        fixedImage={props.card2Fixedimage}
                        alt={props.alt}
                      />
                    </motion.div>
                  </div>
                  <div className="column is-6">
                    <motion.div
                      animate={
                        inView
                          ? {
                              scale: [0, 1.2, 1],
                              opacity: [0, 1],
                            }
                          : {
                              scale: 0,
                              opacity: [0, 0],
                            }
                      }
                      ref={ref}
                      transition={{
                        duration: 1.5,
                        delay: 0.9,
                      }}
                    >
                      <Card
                        cardTitle={props.card3Title}
                        imageCss={props.card3Imagecss}
                        imageStyle={props.card3Imagestyle}
                        fixedImage={props.card3Fixedimage}
                        alt={props.alt}
                      />
                    </motion.div>
                  </div>
                  <div className="column is-6">
                    <motion.div
                      animate={
                        inView
                          ? {
                              scale: [0, 1.2, 1],
                              opacity: [0, 1],
                            }
                          : {
                              scale: 0,
                              opacity: [0, 0],
                            }
                      }
                      ref={ref}
                      transition={{
                        duration: 1.5,
                        delay: 1.2,
                      }}
                    >
                      <Card
                        cardTitle={props.card4Title}
                        imageCss={props.card4Imagecss}
                        imageStyle={props.card4Imagestyle}
                        fixedImage={props.card4Fixedimage}
                        alt={props.alt}
                      />
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section2
