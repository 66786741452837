import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import HeroHeader from "../components/header/heroHeader"
import TextImageRight from "../components/common/textWithRightImage"
import PrimaryQuote from "../components/common/primaryQuote"
import HighlightSeries from "../components/common/highlightsSeries"
import Section2 from "../components/sections/section2"
import Section1 from "../components/sections/section1"
import Seo from "../components/seo/seo"
import { useTranslation } from "react-i18next"
import Modal from "../components/common/modal"
import SubscriptionForm from "../components/forms/subscriptionForm"

const ContractManufacturing = ({ data }) => {
  const { t } = useTranslation();

  return (

  <Layout>
    <Seo
      title={t("index.seoTitle")}
      description={t("index.seoDescription")}
      seoImage={data.produzione.childImageSharp.resize.src}
    />
     <Modal
        //isActive = "is-active"
        component={<SubscriptionForm />}
        //onCloseClick={() => setShowModal(false)}
      /> 
    <HeroHeader
      isGradient="is-gradient"
      fluid={data.cablaggio_industriale.childImageSharp.fluid}
      headerTitle={t("index.headerTitle")}
      headerSubtitle={t("index.headerSubtitle")}
      alt={t("index.headerImageTag")}
    />
    <div className="section is-gradient-bottom-right is-relative">
      {/* <Img
        fluid={data.produzione.childImageSharp.fluid}
        style={{
          position: "absolute",
          overflow: "hidden",
        }}
        imgStyle={{
          width: "100%",
          height: "100%",
          opacity: "0.05",
        }}
        className="image-wrapper-height-auto"
        alt="Alt tag"
      /> */}
      <div className="container">
        <div className="columns">
          <div className="column">
            <TextImageRight
              titleOne={t("index.titleOne1")}
              textOne={t("index.textOne1")}
              titleTwo={t("index.titleTwo1")}
              textTwo={t("index.textTwo1")}
            />
          </div>
          <div className="column">
            <TextImageRight
              titleOne={t("index.titleOne2")}
              textOne={t("index.textOne2")}
              titleTwo={t("index.titleTwo2")}
              textTwo={t("index.textTwo2")}
            />
          </div>
        </div>
      </div>
    </div>
    <PrimaryQuote
      quoteTitle={t("index.quoteTitle")}
      quoteHighlight={t("index.quoteHighlight")}
    />
    <div className="section has-lightgreen-background">
      <HighlightSeries
        title={t("index.hsTitle")}
        mainOne={t("index.hsMainOne")}
        descriptionOne={t("index.hsDescriptionOne")}
        mainTwo={t("index.hsMainTwo")}
        descriptionTwo={t("index.hsDescriptionTwo")}
        mainThree={t("index.hsMainThree")}
        descriptionThree={t("index.hsDescriptionThree")}
        mainFour={t("index.hsMainFour")}
        descriptionFour={t("index.hsDescriptionFour")}
        mainFive={t("index.hsMainFive")}
        descriptionFive={t("index.hsDescriptionFive")}
      />
    </div>

    <Section2
      fluid={data.cliente.childImageSharp.fluid}
      section2Title={t("index.section2Title")}
      card1Title={t("index.card1Title")}
      card2Title={t("index.card2Title")}
      card3Title={t("index.card3Title")}
      card4Title={t("index.card4Title")}
      alt={t("index.section2ImageTag")}
    />

    <Section1
      section1Title={t("index.section1Title")}
      section1Text={t("index.section1Text")}
    />
  </Layout>
  )
}

export default ContractManufacturing

export const ContractManufacturingQuery = graphql`
  query {
    produzione: file(
      relativePath: { eq: "Produzione_Assemblaggio_Prodotti.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    cablaggio_industriale: file(
      relativePath: { eq: "assemblaggio_cablaggio_industriale.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cliente: file(relativePath: { eq: "Customer_Service.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
